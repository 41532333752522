.toggle-theme-button{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 333;
  padding: 5px;
  margin: -3px;
  background-color: var(--throne);
  border-radius: 0 0 10px 0 ;
  box-shadow: var(--shadow-lg);
  cursor: pointer;
}

a{text-decoration: none;}

input[type="number"],input[type="text"],input[type="password"] {
  font-family: "'BoutrosMBCDinkum Medium'";
  padding: 10px;
  outline: none;
  border: none;
  color: var(--shark);
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow:var(--shadow-lg);
  background-color: var(--prim);
}

input::placeholder {
  color: var(--goat);
  letter-spacing: 1px;
  font-size: 14px;
  z-index: 2;
  font-weight: bold;
  position: relative;
}
input[type="number"]:focus,input[type="text"]:focus{box-shadow: var(--shadow-focus);}

.button{
  font-family: "'BoutrosMBCDinkum Medium'";
  background-color: var(--dragon);
  color: var(--white);
  border-radius: 0.5rem;
  padding: var(--md) var(--lg);
  border: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: var(--shadow-lg);
}

.owl-expand{
  max-height: 1800px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

p[state='selected']{
  background-color: var(--penguin);
  }
  p[state='off']{
  background-color: var(--goat);
  }

.svg-icon {
  width: 20px;
  height: 20px;
  /* fill: var(--shark); */
  transition: all 0.2s ease-out;
}

[itemType="active-tab"] .svg-icon { fill: var(--dragon) !important; }

[itemType="active-tab"] {
  color: var(--dragon) !important;
}

[itemType="un-active-tab"] {
  fill: var(--goat) !important;
  color: var(--goat) !important;
}