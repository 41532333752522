.item-card {
    padding: 15px;
    border-radius: 30px;
    transition: all 300ms ease-in-out;
    box-shadow:   0 0 10px #2d303e26;
  }

.item-card-image-container {
    display: flex ;
    flex-direction: column;
  }
  
  .item-card img  {
    height: 100px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 20px ;
    transition: all 200ms ease-out;
  }
  .amount-label {
    visibility: hidden;
  }
  .chosen-t2 .amount-label {
    visibility: visible;
  }
  .price{
    transition: all 300ms ease-in-out;
  }
  .chosen-t2 .price {
    top: -30px;
  }

  .favorite {fill: var(--green);} .un-favorite {fill: var(--goat);}