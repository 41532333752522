@import "containers.css"; 
@import "root.css";
@import "elements.css"; 
@import "animations.css"; 
@import "theme.css"; 
@import "components.css";
:root {
  transition: all 0.3s ease-in-out;

  /* --shark: #8e9a9b;
  --goat: #b9cad4;
  --dolphin: #b1acac;
  --whale: #105588;
  --crow: #150d23; */
  
  --white:#ffffff;
  --black:#000000;
  --error:#f73131;
  --success:#61bb46;
  --warning:#f3d60b;

  --frog: #bada55;
  --penguin: #1fd1ec;

  --cyan:#63cfc9;
  --blue:#40acdc;
  --green:#56e181;

  --fox:#d69e58;
  --wolf: #b2a47b;
  --lion: #b59675;
  --tiger: #8d3c09;

  --dragon: #ec4245;
  --red:#dd3643;
  --red-1:#f29bb9;
  --fire: #e9b11f;

  --flamingo: #f29bb9;
  --purple: #a855f7;
  --jellyfish: #8a41e4;
  --lavender: #e4b1e4;

  --light-gray:  #9ca3af;
  --gray:  #4b5563;

}

.light {  
    --prim:#FFFFFF;   --throne: #FBFBFB; --lord:    #FBFBFB;  --prince: #f6f6f6;  --king:#f6f6f6;
    --crow: #1f1d2b;  --shark:  #c4c4c7; --dolphin: #c3c3c6;  --goat:   #c4c4c7;  --whale: #bebec1;
    --bg:#ffffff; --fill-logo:#2d303e;   --blur:#FBFBFBaa;
    --popup:#2c3e5022;
  }
  
  .dark {
    --prim: #2d303e;   --throne: #353848;   --lord:   #292a39;      --prince: #393c4a;     --king:  #3b3e4c;
    --crow: #ffffff;   --shark:  #c4c4c7;   --dolphin: #6c6e78;     --goat:   #9e9fa6;     --whale: #767982;
    --bg:#1f1d2b;      --fill-logo:#f6f6f6; --blur :#2d303edd ;
    --popup:#FBFBFB22;
}

.bg-white     {background-color: var(--white);}      .text-white     {color: var(--white);}      .fill-white     {fill: var(--white);}      
.bg-black     {background-color: var(--black);}      .text-black     {color: var(--black);}      .fill-black     {fill: var(--black);}      
.bg-frog      {background-color: var(--frog);}       .text-frog      {color: var(--frog);}       .fill-frog      {fill: var(--frog);}       
.bg-penguin   {background-color: var(--penguin);}    .text-penguin   {color: var(--penguin);}    .fill-penguin   {fill: var(--penguin);}    
.bg-crow      {background-color: var(--crow);}       .text-crow      {color: var(--crow);}       .fill-crow      {fill: var(--crow);}       
.bg-fox       {background-color: var(--fox);}        .text-fox       {color: var(--fox);}        .fill-fox       {fill: var(--fox);}        
.bg-wolf      {background-color: var(--wolf);}       .text-wolf      {color: var(--wolf);}       .fill-wolf      {fill: var(--wolf);}       
.bg-lion      {background-color: var(--lion);}       .text-lion      {color: var(--lion);}       .fill-lion      {fill: var(--lion);}       
.bg-tiger     {background-color: var(--tiger);}      .text-tiger     {color: var(--tiger);}      .fill-tiger     {fill: var(--tiger);}      
.bg-dragon    {background-color: var(--dragon);}     .text-dragon    {color: var(--dragon);}     .fill-dragon    {fill: var(--dragon);}     
.bg-fire      {background-color: var(--fire);}       .text-fire      {color: var(--fire);}       .fill-fire      {fill: var(--fire);}       
.bg-flamingo  {background-color: var(--flamingo);}   .text-flamingo  {color: var(--flamingo);}   .fill-flamingo  {fill: var(--flamingo);}   
.bg-jellyfish {background-color: var(--jellyfish);}  .text-jellyfish {color: var(--jellyfish);}  .fill-jellyfish {fill: var(--jellyfish);}  
.bg-lavender  {background-color: var(--lavender);}   .text-lavender  {color: var(--lavender);}   .fill-lavender  {fill: var(--lavender);} 
.bg-cyan      {background-color: var(--cyan);}       .text-cyan      {color: var(--cyan);}       .fill-cyan      {fill: var(--cyan);}
.bg-blue      {background-color: var(--blue);}       .text-blue      {color: var(--blue);}       .fill-blue      {fill: var(--blue);}
.bg-green     {background-color: var(--green);}      .text-green     {color: var(--green);}      .fill-green     {fill: var(--green);}
.bg-yellow    {background-color: var(--yellow);}     .text-yellow    {color: var(--yellow);}     .fill-yellow    {fill: var(--yellow);}
.bg-orange    {background-color: var(--orange);}     .text-orange    {color: var(--orange);}     .fill-orange    {fill: var(--orange);}
.bg-red       {background-color: var(--red);}        .text-red       {color: var(--red);}        .fill-red       {fill: var(--red);}
.bg-purple    {background-color: var(--purple);}     .text-purple    {color: var(--purple);}     .fill-purple    {fill: var(--purple);}
.bg-pink      {background-color: var(--pink);}       .text-pink      {color: var(--pink);}       .fill-pink      {fill: var(--pink);}
.bg-brown     {background-color: var(--brown);}      .text-brown     {color: var(--brown);}      .fill-brown     {fill: var(--brown);}
.bg-gray      {background-color: var(--gray);}       .text-gray      {color: var(--gray);}       .fill-gray      {fill: var(--gray);}
.bg-silver    {background-color: var(--silver);}     .text-silver    {color: var(--silver);}     .fill-silver    {fill: var(--silver);}


.bg-prim    {background-color: var(--prim);}     .text-prim    {color: var(--prim);}     .fill-prim    {fill: var(--prim);}     
.bg-throne  {background-color: var(--throne);}   .text-throne  {color: var(--throne);}   .fill-throne  {fill: var(--throne);}   
.bg-lord    {background-color: var(--lord);}     .text-lord    {color: var(--lord);}     .fill-lord    {fill: var(--lord);}     
.bg-prince  {background-color: var(--prince);}   .text-prince  {color: var(--prince);}   .fill-prince  {fill: var(--prince);}   
.bg-king    {background-color: var(--king);}     .text-king    {color: var(--king);}     .fill-king    {fill: var(--king);}     
.bg-crow    {background-color: var(--crow);}     .text-crow    {color: var(--crow);}     .fill-crow    {fill: var(--crow);}     
.bg-shark   {background-color: var(--shark);}    .text-shark   {color: var(--shark);}    .fill-shark   {fill: var(--shark);}    
.bg-dolphin {background-color: var(--dolphin);}  .text-dolphin {color: var(--dolphin);}  .fill-dolphin {fill: var(--dolphin);}  
.bg-goat    {background-color: var(--goat);}     .text-goat    {color: var(--goat);}     .fill-goat    {fill: var(--goat);}     
.bg-whale   {background-color: var(--whale);}    .text-whale   {color: var(--whale);}    .fill-whale   {fill: var(--whale);}    
.bg-popup   {background-color: var(--popup);}    .text-popup   {color: var(--popup);}    .fill-popup   {fill: var(--popup);}
.fill-logo{fill: var(--fill-logo);}

.bg-blur    {background-color:var(--blur); backdrop-filter: blur(3px);}
/* .svg-icon   {fill: var(--crow);} */