@import "css/index.css";
@import "Pages/pages.css";
body{
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
}


.error-status-color-0 {color:#a875ff}
.error-status-color-1 {color:#ff4081}
.error-status-color-2 {color:#e50000}
.error-status-color-3 {color:#6BC950}
.error-status-color-4 {color:#D3D3D3}
.error-status-color-5 {color:#2ECD6F}

.logs-level-debug {color: #A35200}
.logs-level-info {color: #1890FF}
.logs-level-warn {color: #FAAD14}
.logs-level-error {color: #F5222D}
.logs-level-fatal { color: #FF1010}